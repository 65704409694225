import * as httpService from './HttpService';
import { IUser } from '../model/User';
import { post } from './HttpService';

const SCRANTHAPP_API_URL = process.env.REACT_APP_SCRANTHAPP_API_URL;

export const getLoggedInUser = async (accessToken: string): Promise<any> => {
  const resp = await httpService.get(`${SCRANTHAPP_API_URL}/v1/users/me`, null, accessToken);

  return resp ? resp.data : null;
};

export const register = async (name: string, email: string, password: string) => {
  const body = {
    callback: `${window.location.origin}/verify`,
    name,
    email,
    password,
  };

  await await post(`${SCRANTHAPP_API_URL}/v1/users/register`, body);
};

export const verify = async (code: string) => {
  const body = {
    code,
  };

  const resp = await await post(`${SCRANTHAPP_API_URL}/v1/users/verify`, body);

  return resp && resp.data ? resp.data.email : null;
};

export const forgotPassword = async (email: string) => {
  const body = {
    callback: `${window.location.origin}/reset-password`,
    email,
  };

  await post(`${SCRANTHAPP_API_URL}/v1/users/forgot-password`, body);
};

export const resetPassword = async (code: string, password: string) => {
  const body = {
    code,
    password,
  };

  const resp = await post(`${SCRANTHAPP_API_URL}/v1/users/reset-password`, body);

  return resp && resp.data ? resp.data.email : null;
};

export const deleteAccount = async (accessToken: string): Promise<void> => {
  await httpService.del(`${SCRANTHAPP_API_URL}/v1/users/me`, accessToken);
};

export const getUserName = (user: IUser | null) => {
  if (user && user.password) {
    return user.password.name;
  } else if (user && user.facebook) {
    return user.facebook.name;
  } else if (user && user.google) {
    return user.google.name;
  } else if (user && user.clientCredentials) {
    return 'Client credentials user';
  } else {
    return 'User';
  }
};

export const getUserPicture = (user: IUser | null) => {
  if (user && user.password) {
    return '/solid-color-image.jpg';
  } else if (user && user.facebook) {
    return user.facebook.picture;
  } else if (user && user.google) {
    return user.google.picture;
  } else if (user && user.clientCredentials) {
    return '/solid-color-image.jpg';
  } else {
    return '/solid-color-image.jpg';
  }
};
