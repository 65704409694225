import { createContext, useContext, useState, ReactNode } from 'react';

const MessageContext = createContext<MessageContextType>(null!);

interface MessageContextType {
  infoMessage: string;
  errorMessage: string;
  setInfoMessage: (infoMessage: string) => void;
  setErrorMessage: (errorMessage: string) => void;
  clearMessage: () => void;
}

export const MessageProvider = ({ children }: { children: ReactNode }) => {
  const [infoMessage, _setInfoMessage] = useState<string>('');
  const [errorMessage, _setErrorMessage] = useState<string>('');

  const clearMessage = () => {
    _setInfoMessage('');
    _setErrorMessage('');
  };

  const setInfoMessage = (infoMessage: string) => {
    _setInfoMessage(infoMessage);

    setTimeout(() => {
      clearMessage();
    }, 5000);
  };

  const setErrorMessage = (errorMessage: string) => {
    _setErrorMessage(errorMessage);

    setTimeout(() => {
      clearMessage();
    }, 5000);
  };

  return (
    <MessageContext.Provider
      value={
        {
          infoMessage,
          errorMessage,
          setInfoMessage,
          setErrorMessage,
          clearMessage,
        } as MessageContextType
      }
    >
      {children}
    </MessageContext.Provider>
  );
};

export const useMessage = () => {
  return useContext(MessageContext);
};
