import * as httpService from './HttpService';
import { IPageable } from '../model/Pageable';
import { IFile } from '../model/File';
import { PaginatedResponse } from '../model/PaginatedResponse';

const SCRANTHAPP_API_URL = process.env.REACT_APP_SCRANTHAPP_API_URL;

export const getFiles = async (
  accessToken: string,
  pageable?: IPageable
): Promise<PaginatedResponse<IFile[]>> => {
  const params = {
    ...(pageable && pageable.limit && { limit: pageable.limit }),
    ...(pageable && pageable.startValue && { startValue: pageable.startValue }),
    ...(pageable && pageable.sort && { sort: pageable.sort }),
  };

  const resp = await httpService.get(`${SCRANTHAPP_API_URL}/v1/files`, params, accessToken);

  return resp ? resp.data : null;
};

export const getFileById = async (accessToken: string, fileId: string): Promise<IFile> => {
  const resp = await httpService.get(`${SCRANTHAPP_API_URL}/v1/files/${fileId}`, null, accessToken);

  return resp ? resp.data : null;
};

export const deleteFile = async (accessToken: string, fileId: string): Promise<void> => {
  await httpService.del(`${SCRANTHAPP_API_URL}/v1/files/${fileId}`, accessToken);
};

export const uploadFile = async (accessToken: string, formData: any): Promise<any> => {
  const resp = await httpService.post(
    `${SCRANTHAPP_API_URL}/v1/files/upload`,
    formData,
    accessToken,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return resp || null;
};

export const downloadFile = async (accessToken: string, fileId: string): Promise<any> => {
  const resp = await httpService.get(
    `${SCRANTHAPP_API_URL}/v1/files/download/${fileId}`,
    null,
    accessToken,
    {
      responseType: 'blob',
    }
  );

  return resp || null;
};
