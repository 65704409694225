import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import { useAuth } from '../hooks/useAuth';
import * as subscriptionService from '../service/SubscriptionService';
import * as subscriptionMembershipService from '../service/SubscriptionMembershipService';
import * as projectService from '../service/ProjectService';
import * as projectMembershipService from '../service/ProjectMembershipService';
import Loading from './Loading';
import './Users.scss';
import { useMessage } from '../hooks/useMessage';
import * as errorService from '../service/ErrorService';
import { IProject } from '../model/Project';
import { IProjectMembership } from '../model/ProjectMembership';
import { ISubscription } from '../model/Subscription';
import { ISubscriptionMembership } from '../model/SubscriptionMembership';
import * as dateUtil from '../util/DateUtil';

function Users() {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [projectMemberships, setProjectMemberships] = useState<IProjectMembership[]>([]);
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [subscriptionMemberships, setSubscriptionMemberships] = useState<ISubscriptionMembership[]>(
    []
  );
  const [loading, setLoading] = useState(true);

  let { accessToken, refresh, user } = useAuth();
  let message = useMessage();

  useEffect(() => {
    const fetchData = async () => {
      const paginatedSubscriptions = await subscriptionService.getSubscriptions(accessToken);

      const subscriptions = paginatedSubscriptions ? paginatedSubscriptions.data : [];

      setSubscriptions(subscriptions);

      const paginatedSubscriptionMembershipsArray = await Promise.all(
        subscriptions.map((subscription: ISubscription) => {
          return subscriptionMembershipService.getSubscriptionMemberships(
            accessToken,
            subscription.id || ''
          );
        })
      );

      const paginatedSubscriptionMemberships = paginatedSubscriptionMembershipsArray.flat();

      const subscriptionMemberships = paginatedSubscriptionMemberships
        .map((paginatedSubscriptionMemberships) => paginatedSubscriptionMemberships.data)
        .flat();

      setSubscriptionMemberships(subscriptionMemberships);

      const paginatedProjects = await projectService.getProjects(accessToken);

      const projects = paginatedProjects ? paginatedProjects.data : [];

      setProjects(projects);

      const paginatedProjectMembershipsArray = await Promise.all(
        projects.map((project: IProject) => {
          return projectMembershipService.getProjectMemberships(
            accessToken,
            project.id || '',
            true
          );
        })
      );

      const paginatedProjectMemberships = paginatedProjectMembershipsArray.flat();

      const projectMemberships = paginatedProjectMemberships
        .map((paginatedProjectMemberships) => paginatedProjectMemberships.data)
        .flat();

      setProjectMemberships(projectMemberships);
    };

    fetchData()
      .catch((err: any) =>
        errorService.handleComponentError(
          err,
          'Error loading users',
          message,
          refresh,
          user ? user.id : null
        )
      )
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <div className="users-loading-container">
          <Loading />
        </div>
      ) : (
        <Container className="users-container">
          <div className="subscription-users-table">
            <Row>
              <Col sm="12">Subscription users:</Col>
            </Row>
            {subscriptions && subscriptionMemberships.length > 0 && (
              <Row className="users-table">
                <Col sm="12">
                  <Table size="sm" striped responsive>
                    <thead>
                      <tr>
                        <th>Subscription</th>
                        <th>User</th>
                        <th>Role</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptionMemberships.map((subscriptionMembership) => (
                        <tr key={subscriptionMembership.id}>
                          <td>
                            {
                              subscriptions.find(
                                (subscription) =>
                                  subscription.id === subscriptionMembership.subscriptionId
                              )?.name
                            }
                          </td>
                          <td>{subscriptionMembership.userName}</td>
                          <td>{subscriptionMembership.role}</td>
                          <td>
                            {dateUtil.format(subscriptionMembership.created, 'MM/DD/YYYY HH:mm:ss')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
          </div>

          <div className="project-users-table">
            <Row>
              <Col sm="12">Project users:</Col>
            </Row>
            {projects && projectMemberships.length > 0 && (
              <Row className="users-table">
                <Col sm="12">
                  <Table size="sm" striped responsive>
                    <thead>
                      <tr>
                        <th>Project</th>
                        <th>User</th>
                        <th>Role</th>
                        <th>Scopes</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectMemberships.map((projectMembership) => (
                        <tr key={projectMembership.id}>
                          <td>
                            {
                              projects.find((project) => project.id === projectMembership.projectId)
                                ?.name
                            }
                          </td>
                          <td>{projectMembership.userName}</td>
                          <td>{projectMembership.role}</td>
                          <td>{projectMembership.scopes ? `[${projectMembership.scopes}]` : ''}</td>
                          <td>
                            {dateUtil.format(projectMembership.created, 'MM/DD/YYYY HH:mm:ss')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
          </div>
        </Container>
      )}
    </>
  );
}

export default Users;
