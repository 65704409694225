import { Provider } from '../model/Provider';
import * as authService from '../service/AuthService';

export const loginPasswordProvider = async (email: string, password: string): Promise<any> => {
  return await authService.createAccessToken(Provider.Password, email, password, null);
};

export const loginSocialProvider = async (
  provider: Provider,
  accessToken: string
): Promise<any> => {
  return await authService.createAccessToken(provider, null, null, accessToken);
};
