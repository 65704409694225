import * as httpService from './HttpService';
import { IPageable } from '../model/Pageable';
import { PaginatedResponse } from '../model/PaginatedResponse';
import { IProjectMembership } from '../model/ProjectMembership';

const SCRANTHAPP_API_URL = process.env.REACT_APP_SCRANTHAPP_API_URL;

export const getProjectMemberships = async (
  accessToken: string,
  projectId: string,
  includeUserName: boolean,
  pageable?: IPageable
): Promise<PaginatedResponse<IProjectMembership[]>> => {
  const params = {
    ...(pageable && pageable.limit && { limit: pageable.limit }),
    ...(pageable && pageable.startValue && { startValue: pageable.startValue }),
    ...(pageable && pageable.sort && { sort: pageable.sort }),
  };

  const resp = await httpService.get(
    `${SCRANTHAPP_API_URL}/v1/projects/${projectId}/memberships?includeUserName=${includeUserName}`,
    params,
    accessToken
  );

  return resp ? resp.data : null;
};

export const getProjectMembershipsByProjectMembershipIds = async (
  accessToken: string,
  projectId: string,
  projectMembershipIds: string[],
  pageable?: IPageable
): Promise<PaginatedResponse<IProjectMembership[]>> => {
  const params = {
    projectMembershipIds,
    ...(pageable && pageable.limit && { limit: pageable.limit }),
    ...(pageable && pageable.startValue && { startValue: pageable.startValue }),
    ...(pageable && pageable.sort && { sort: pageable.sort }),
  };

  const resp = await httpService.post(
    `${SCRANTHAPP_API_URL}/v1/projects/${projectId}/memberships/search?includeUserName=true`,
    params,
    accessToken
  );

  return resp ? resp.data : null;
};
