export const logger = {
  debug: (message: string, meta?: any) => {
    // tslint:disable-next-line:no-console
    console.log(message, meta);
  },
  info: (message: string, meta?: any) => {
    // tslint:disable-next-line:no-console
    console.log(message, meta);
  },
  warn: (message: string, meta?: any) => {
    // tslint:disable-next-line:no-console
    console.log(message, meta);
  },
  error: (message: string, meta?: any) => {
    // tslint:disable-next-line:no-console
    console.error(message, meta);
  },
};
