import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import './Subscriptions.scss';
import { useAuth } from '../hooks/useAuth';
import { useMessage } from '../hooks/useMessage';
import * as subscriptionService from '../service/SubscriptionService';
import * as errorService from '../service/ErrorService';
import Loading from './Loading';
import { ValidationError } from '../model/ValidationError';
import { ISubscription } from '../model/Subscription';
import * as dateUtil from '../util/DateUtil';
import Pagination from './Pagination';
import { IPageable } from '../model/Pageable';

const ITEMS_PER_PAGE = 10;

function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [subscription, setSubscription] = useState<ISubscription | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string>('');

  const toggleCreateModal = () => setCreateModal(!createModal);
  const toggleEditModal = () => setEditModal(!editModal);

  let { accessToken, refresh, user } = useAuth();
  let message = useMessage();

  useEffect(() => {
    const fetchData = async () => {
      const paginatedSubscriptions = await subscriptionService.getSubscriptions(accessToken, {
        limit: ITEMS_PER_PAGE,
      });

      setSubscriptions(paginatedSubscriptions ? paginatedSubscriptions.data : []);
    };

    fetchData()
      .catch((err: any) =>
        errorService.handleComponentError(
          err,
          'Error loading subscriptions',
          message,
          refresh,
          user ? user.id : null
        )
      )
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateModal = async () => {
    message.clearMessage();

    setValidationError('');

    toggleCreateModal();
  };

  const closeCreateModal = async () => {
    toggleCreateModal();
  };

  const openEditModal = async (subscriptionId: string) => {
    message.clearMessage();

    setValidationError('');

    try {
      const subscription = await subscriptionService.getSubscriptionById(
        accessToken,
        subscriptionId
      );

      setSubscription(subscription);
    } catch (err: any) {
      await onError(err);
    }

    toggleEditModal();
  };

  const closeEditModal = async () => {
    toggleEditModal();
  };

  const handleCreateSubmit = async (e: any) => {
    e.preventDefault();

    let formData = new FormData(e.currentTarget);
    let name = formData.get('name') as string;
    let orgName = formData.get('orgName') as string;
    let planType = formData.get('planType') as string;

    if (!name.trim()) {
      setValidationError('Name is required');

      return;
    } else if (!planType.trim()) {
      setValidationError('Plan Type is required');

      return;
    }

    try {
      await subscriptionService.createSubscription(accessToken, name, orgName, planType);

      const paginatedSubscriptions = await subscriptionService.getSubscriptions(accessToken, {
        limit: ITEMS_PER_PAGE,
      });

      setSubscriptions(paginatedSubscriptions.data);

      setValidationError('');

      toggleCreateModal();

      message.setInfoMessage('Subscription successfully created');
    } catch (err: any) {
      if (err instanceof ValidationError) {
        setValidationError(err.message);
      } else {
        await onError(err);

        setValidationError('');

        toggleEditModal();
      }
    }
  };

  const handleEditSubmit = async (e: any, subscriptionId: string, version: number) => {
    e.preventDefault();

    let formData = new FormData(e.currentTarget);
    let name = formData.get('name') as string;
    let orgName = formData.get('orgName') as string;
    let planType = formData.get('planType') as string;

    if (!name.trim()) {
      setValidationError('Name is required');

      return;
    } else if (!planType.trim()) {
      setValidationError('Plan Type is required');

      return;
    }

    try {
      await subscriptionService.updateSubscription(
        accessToken,
        subscriptionId,
        name,
        orgName,
        planType,
        version
      );

      const paginatedSubscriptions = await subscriptionService.getSubscriptions(accessToken, {
        limit: ITEMS_PER_PAGE,
      });

      setSubscriptions(paginatedSubscriptions.data);

      setValidationError('');

      toggleEditModal();

      message.setInfoMessage('Subscription successfully updated');
    } catch (err: any) {
      if (err instanceof ValidationError) {
        setValidationError(err.message);
      } else {
        await onError(err);

        setValidationError('');

        toggleEditModal();
      }
    }
  };

  const handleDeleteSubmit = async (e: any, subscriptionId: string) => {
    e.preventDefault();

    if (
      window.confirm(
        'Are you sure?\n\n*Note: This will delete all related projects and user associations'
      )
    ) {
      try {
        await subscriptionService.deleteSubscription(accessToken, subscriptionId);

        const paginatedSubscriptions = await subscriptionService.getSubscriptions(accessToken, {
          limit: ITEMS_PER_PAGE,
        });

        setSubscriptions(paginatedSubscriptions.data);

        setValidationError('');

        toggleEditModal();
      } catch (err: any) {
        if (err instanceof ValidationError) {
          setValidationError(err.message);
        } else {
          await onError(err);

          setValidationError('');

          toggleEditModal();
        }
      }
    }
  };

  const onError = async (err: any): Promise<void> => {
    await errorService.handleComponentError(
      err,
      'Error retrieving items',
      message,
      refresh,
      user ? user.id : null
    );
  };

  const fetchItemsFunction = async (pageable: IPageable): Promise<any> => {
    const paginatedSubscriptions = await subscriptionService.getSubscriptions(
      accessToken,
      pageable
    );

    return paginatedSubscriptions.data || [];
  };

  return (
    <>
      {loading ? (
        <div className="subscriptions-loading-container">
          <Loading />
        </div>
      ) : (
        <Container className="subscriptions-container">
          <Row>
            <Col sm="12">
              <Button color="success" onClick={openCreateModal}>
                Add Subscription
              </Button>

              <Modal isOpen={createModal} toggle={toggleCreateModal}>
                <ModalHeader toggle={toggleCreateModal}>
                  <div>Add Subscription</div>
                  {validationError && (
                    <div className="modal-validation-error">{validationError}</div>
                  )}
                </ModalHeader>

                <ModalBody>
                  <div>
                    <Form onSubmit={handleCreateSubmit}>
                      <FormGroup>
                        <Label for="name">Name</Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          maxLength={100}
                          placeholder="ex. my new subscription"
                        />
                        <FormText>(Required) Add a name for your subscription</FormText>
                      </FormGroup>

                      <FormGroup>
                        <Label for="orgName">Org Name</Label>
                        <Input type="text" name="orgName" id="orgName" maxLength={100} />
                        <FormText>(Optional) Add your organization name</FormText>
                      </FormGroup>

                      <FormGroup>
                        <Label for="planType">Plan Type</Label>
                        <Input type="select" name="planType" id="planType">
                          <option value="">- select -</option>
                          <option value="free">Free</option>
                          <option value="basic">Basic</option>
                          <option value="pro">Pro</option>
                        </Input>
                        <FormText>(Required) Choose a plan type</FormText>
                      </FormGroup>

                      <FormGroup className="modal-footer subscription-modal-footer">
                        <Button type="submit" color="primary">
                          Save
                        </Button>
                        <Button color="secondary" onClick={closeCreateModal}>
                          Cancel
                        </Button>
                      </FormGroup>
                    </Form>
                  </div>
                </ModalBody>
              </Modal>

              {subscription && (
                <Modal isOpen={editModal} toggle={toggleEditModal}>
                  <ModalHeader toggle={toggleEditModal}>
                    <div>Edit Subscription</div>
                    {validationError && (
                      <div className="modal-validation-error">{validationError}</div>
                    )}
                  </ModalHeader>

                  <ModalBody>
                    <div>
                      <Form
                        onSubmit={(evt) =>
                          handleEditSubmit(
                            evt,
                            subscription['id'] || '',
                            subscription['version'] || 0
                          )
                        }
                      >
                        <FormGroup>
                          <Label for="name">Name</Label>
                          <Input
                            type="text"
                            name="name"
                            id="name"
                            maxLength={100}
                            defaultValue={subscription['name']}
                          />
                          <FormText>(Required) Add a name for your subscription</FormText>
                        </FormGroup>

                        <FormGroup>
                          <Label for="orgName">Org Name</Label>
                          <Input
                            type="text"
                            name="orgName"
                            id="orgName"
                            maxLength={100}
                            defaultValue={subscription['orgName']}
                          />
                          <FormText>(Optional) Add your organization name</FormText>
                        </FormGroup>

                        <FormGroup>
                          <Label for="planType">Plan Type</Label>
                          <Input
                            type="select"
                            name="planType"
                            id="planType"
                            defaultValue={subscription['planType']}
                          >
                            <option value="">- select -</option>
                            <option value="free">Free</option>
                            <option value="basic">Basic</option>
                            <option value="pro">Pro</option>
                          </Input>
                          <FormText>(Required) Choose a plan type</FormText>
                        </FormGroup>

                        <FormGroup className="modal-footer subscription-modal-footer">
                          <Button type="submit" color="primary">
                            Save
                          </Button>
                          <Button color="secondary" onClick={closeEditModal}>
                            Cancel
                          </Button>
                          <Button
                            color="danger"
                            onClick={(evt) => handleDeleteSubmit(evt, subscription['id'] || '')}
                          >
                            Delete
                          </Button>
                        </FormGroup>
                      </Form>
                    </div>
                  </ModalBody>
                </Modal>
              )}
            </Col>
          </Row>

          {subscriptions.length > 0 && (
            <Row className="subscriptions-table">
              <Col sm="12">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Org Name</th>
                      <th>Plan Type</th>
                      <th>Created</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {subscriptions.map((subscription) => (
                      <tr key={subscription['id']}>
                        <td>{subscription['name']}</td>
                        <td>{subscription['orgName']}</td>
                        <td className="uppercase-text">{subscription['planType']}</td>
                        <td>{dateUtil.format(subscription['created'], 'MM/DD/YYYY HH:mm:ss')}</td>
                        <td>
                          <Button
                            color="secondary"
                            onClick={() => openEditModal(subscription['id'] || '')}
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}

          {subscriptions.length > 0 && (
            <Row>
              <Col sm="12" className="subscriptions-pagination">
                <Pagination
                  items={subscriptions}
                  setItems={setSubscriptions}
                  fetchItemsFunction={fetchItemsFunction}
                  limit={ITEMS_PER_PAGE}
                  onError={onError}
                />
              </Col>
            </Row>
          )}

          {subscriptions.length === 0 && (
            <Row className="first-subscription-message">
              <Col sm="12">It's time to create your first subscription!</Col>
            </Row>
          )}
        </Container>
      )}
    </>
  );
}

export default Subscriptions;
