import * as eventService from './EventService';
import { stringifyError } from '../util/StringUtil';
import { UnauthorizedError } from '../model/UnauthorizedError';
import * as dateUtil from '../util/DateUtil';

export const handleComponentError = async (
  error: any,
  errorMessage?: string,
  messageHook?: any,
  refresh?: any,
  userId?: string | null
) => {
  if (error instanceof UnauthorizedError) {
    if (refresh) {
      await refresh();
    }
  } else {
    if (messageHook) {
      messageHook.setErrorMessage(errorMessage || 'An unexpected error occurred.');
    }

    await eventService.captureError(
      errorMessage || 'An unexpected error occurred.',
      stringifyError(error),
      userId || '',
      dateUtil.now()
    );
  }
};
