import { Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import Home from './Home';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import Register from './Register';
import Verify from './Verify';
import { ProtectedRoute } from './ProtectedRoute';
import { useAuth } from '../hooks/useAuth';
import Dashboard from './Dashboard';
import MessageBar from './MessageBar';
import NavigationBar from './NavigationBar';
import Settings from './Settings';
import { MessageProvider } from '../hooks/useMessage';
import Logout from './Logout';
import Loading from './Loading';
import Projects from './Projects';
import Subscriptions from './Subscriptions';
import Fallback from './Fallback';
import { ErrorBoundary } from 'react-error-boundary';
import * as errorService from '../service/ErrorService';
import { logger } from '../service/LogService';
import Users from './Users';
import Files from './Files';
import Charts from './Charts';

function App() {
  let { loading } = useAuth();

  const logError = (error: Error, info: { componentStack: string }) => {
    errorService
      .handleComponentError(error, 'An unexpected error occurred')
      .then(() => logger.error('Additional info', info));
  };

  return (
    <>
      {loading ? (
        <div className="app-loading-container">
          <Loading />
        </div>
      ) : (
        <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
          <MessageProvider>
            <MessageBar />
            <NavigationBar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/register" element={<Register />} />
              <Route path="/verify" element={<Verify />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard/subscriptions"
                element={
                  <ProtectedRoute>
                    <Subscriptions />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard/projects"
                element={
                  <ProtectedRoute>
                    <Projects />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard/users"
                element={
                  <ProtectedRoute>
                    <Users />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard/files"
                element={
                  <ProtectedRoute>
                    <Files />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard/charts"
                element={
                  <ProtectedRoute>
                    <Charts />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard/settings"
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </MessageProvider>
        </ErrorBoundary>
      )}
    </>
  );
}

export default App;
