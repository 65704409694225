import * as httpService from './HttpService';
import { logger } from './LogService';
import { EventType } from '../model/EventType';
import { IEvent } from '../model/Event';
import { PaginatedResponse } from '../model/PaginatedResponse';
import { IPageable } from '../model/Pageable';
import * as stringUtil from '../util/StringUtil';

const SCRANTHAPP_API_URL = process.env.REACT_APP_SCRANTHAPP_API_URL;

export const getAnalytics = async (
  accessToken: string,
  pageable?: IPageable
): Promise<PaginatedResponse<IEvent[]>> => {
  const params = {
    ...(pageable && pageable.limit && { limit: pageable.limit }),
    ...(pageable && pageable.startValue && { startValue: pageable.startValue }),
    ...(pageable && pageable.sort && { sort: pageable.sort }),
  };

  const resp = await httpService.get(`${SCRANTHAPP_API_URL}/v1/analytics`, params, accessToken);

  return resp ? resp.data : null;
};

export const getErrors = async (
  accessToken: string,
  pageable?: IPageable
): Promise<PaginatedResponse<IEvent[]>> => {
  const params = {
    ...(pageable && pageable.limit && { limit: pageable.limit }),
    ...(pageable && pageable.startValue && { startValue: pageable.startValue }),
    ...(pageable && pageable.sort && { sort: pageable.sort }),
  };

  const resp = await httpService.get(`${SCRANTHAPP_API_URL}/v1/errors`, params, accessToken);

  return resp ? resp.data : null;
};

export const captureAnalytics = async (
  message: string,
  payload: any,
  userId: string,
  timestamp: Date
): Promise<void> => {
  try {
    await httpService.post(`${SCRANTHAPP_API_URL}/v1/analytics`, {
      eventType: EventType.Analytics,
      message,
      payload,
      userId,
      timestamp,
    });
  } catch (err: any) {
    logger.error('Error sending analytics information to event-api', err);
  }
};

export const captureError = async (
  message: string,
  error: any,
  userId: string,
  timestamp: Date
): Promise<void> => {
  logger.error(message, error);

  try {
    await httpService.post(`${SCRANTHAPP_API_URL}/v1/errors`, {
      eventType: EventType.Error,
      message,
      stacktrace: stringUtil.stringifyError(error),
      timestamp,
      userId,
    });
  } catch (err: any) {
    logger.error('Error sending error information to event-api', err);
  }
};
