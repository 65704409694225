import * as httpService from './HttpService';
import { PaginatedResponse } from '../model/PaginatedResponse';
import { IUserSettings } from '../model/UserSettings';
import { IPageable } from '../model/Pageable';

const SCRANTHAPP_API_URL = process.env.REACT_APP_SCRANTHAPP_API_URL;

export const getUserSettings = async (
  accessToken: string,
  userId: string,
  pageable?: IPageable
): Promise<PaginatedResponse<IUserSettings[]>> => {
  const params = {
    ...(pageable && pageable.limit && { limit: pageable.limit }),
    ...(pageable && pageable.startValue && { startValue: pageable.startValue }),
    ...(pageable && pageable.sort && { sort: pageable.sort }),
  };

  const resp = await httpService.get(
    `${SCRANTHAPP_API_URL}/v1/users/${userId}/settings`,
    params,
    accessToken
  );

  return resp ? resp.data : null;
};

export const getUserSettingsByUserSettingsIds = async (
  accessToken: string,
  userId: string,
  userSettingsIds: string[],
  pageable?: IPageable
): Promise<PaginatedResponse<IUserSettings[]>> => {
  const params = {
    userSettingsIds,
    ...(pageable && pageable.limit && { limit: pageable.limit }),
    ...(pageable && pageable.startValue && { startValue: pageable.startValue }),
    ...(pageable && pageable.sort && { sort: pageable.sort }),
  };

  const resp = await httpService.post(
    `${SCRANTHAPP_API_URL}/v1/users/${userId}/settings/search`,
    params,
    accessToken
  );

  return resp ? resp.data : null;
};

export const updateUserSettings = async (
  accessToken: string,
  userSettingsId: string,
  userId: string,
  displayChartDashboard: boolean,
  maxProjectUsers: number,
  favColor: string,
  version: number
): Promise<IUserSettings> => {
  const resp = await httpService.put(
    `${SCRANTHAPP_API_URL}/v1/users/${userId}/settings/${userSettingsId}`,
    {
      userId,
      displayChartDashboard,
      maxProjectUsers,
      favColor,
      version,
    },
    accessToken
  );

  return resp ? resp.data : null;
};
