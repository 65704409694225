import { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../model/User';
import * as authProvider from '../provider/AuthProvider';
import * as userService from '../service/UserService';
import * as authService from '../service/AuthService';
import { Provider } from '../model/Provider';

const AuthContext = createContext<AuthContextType>(null!);

interface AuthContextType {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  user: IUser | null;
  accessToken: string;
  loginPasswordProvider: (email: string, password: string) => Promise<void>;
  loginSocialProvider: (provider: Provider, accessToken: string) => Promise<void>;
  logout: () => void;
  refresh: () => void;
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<IUser | null>(null);
  const [accessToken, _setAccessToken] = useState(() => {
    return window.localStorage.getItem('accessToken') || '';
  });

  const navigate = useNavigate();

  const setAccessToken = (accessToken: string) => {
    if (accessToken) {
      window.localStorage.setItem('accessToken', accessToken);
    } else {
      window.localStorage.removeItem('accessToken');
    }

    _setAccessToken(accessToken);
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await userService.getLoggedInUser(accessToken);

        setUser(user);
      } catch (err) {
        setAccessToken('');
        setUser(null);
      }
    };

    if (accessToken) {
      fetchUser().then(() => setLoading(false));
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let loginPasswordProvider = async (email: string, password: string) => {
    const _accessToken = await authProvider.loginPasswordProvider(email, password);
    const _user = await userService.getLoggedInUser(_accessToken);

    setAccessToken(_accessToken);
    setUser(_user);
    setLoading(false);
  };

  let loginSocialProvider = async (provider: Provider, accessToken: string) => {
    const _accessToken = await authProvider.loginSocialProvider(provider, accessToken);
    const _user = await userService.getLoggedInUser(_accessToken);

    setAccessToken(_accessToken);
    setUser(_user);
    setLoading(false);
  };

  let logout = () => {
    setAccessToken('');
    setUser(null);
    setLoading(false);

    navigate('/login');
  };

  let refresh = async () => {
    try {
      const _accessToken = await authService.refreshAccessToken(accessToken);

      const _user = await userService.getLoggedInUser(_accessToken);

      setAccessToken(_accessToken);
      setUser(_user);
      setLoading(false);
    } catch (err: any) {
      setUser(null);
      setAccessToken('');
      setLoading(false);

      navigate('/login');
    }
  };

  return (
    <AuthContext.Provider
      value={
        {
          loading,
          setLoading,
          user,
          accessToken,
          loginPasswordProvider,
          loginSocialProvider,
          logout,
          refresh,
        } as AuthContextType
      }
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
