import React, { useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import Loading from './Loading';
import './Logout.scss';

function Logout() {
  let auth = useAuth();

  useEffect(() => {
    auth.logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="logout-container">
      <div className="logout-loading-container">
        <Loading />
      </div>
    </div>
  );
}

export default Logout;
