import axios from 'axios';
import { ValidationError } from '../model/ValidationError';
import { UnauthorizedError } from '../model/UnauthorizedError';
import { ForbiddenError } from '../model/ForbiddenError';
import { NotFoundError } from '../model/NotFoundError';
import { InternalServerError } from '../model/InternalServerError';

export const handleHttpError = (err: any) => {
  if (axios.isAxiosError(err)) {
    if (err.response && err.response.status === 400) {
      throw new ValidationError(err.response.data.message);
    } else if (err.response && err.response.status === 401) {
      throw new UnauthorizedError(err.response.data.message);
    } else if (err.response && err.response.status === 403) {
      throw new ForbiddenError(err.response.data.message);
    } else if (err.response && err.response.status === 404) {
      throw new NotFoundError(err.response.data.message);
    } else if (err.response && err.response.status === 500) {
      throw new InternalServerError(err.response.data.message);
    } else {
      throw err;
    }
  } else {
    throw err;
  }
};
