import React from 'react';
import * as fileService from '../service/FileService';

interface Props {
  subscriptionId: string;
  projectId?: string;
  accessToken: string;
  onUpload: () => Promise<any>;
}

function FileUpload(props: Props) {
  const handleFileUpload = async (evt: any) => {
    const file = evt.target.files[0];

    const formData = new FormData();
    formData.append('file', file);
    formData.append('subscriptionId', props.subscriptionId);

    if (props.projectId) {
      formData.append('projectId', props.projectId);
    }

    await fileService.uploadFile(props.accessToken, formData);

    await props.onUpload();
  };

  return (
    <div>
      <input type="file" onChange={handleFileUpload} />
    </div>
  );
}

export default FileUpload;
