import React, { useEffect } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useGoogleLogin } from '@react-oauth/google';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import './Login.scss';
import { useAuth } from '../hooks/useAuth';
import { useMessage } from '../hooks/useMessage';
import { Provider } from '../model/Provider';
import Loading from './Loading';
import * as errorService from '../service/ErrorService';

function Login() {
  let auth = useAuth();
  let message = useMessage();

  let { loading, setLoading, user, loginPasswordProvider, loginSocialProvider } = useAuth();

  let navigate = useNavigate();
  let location = useLocation();

  let from = location.state?.from?.pathname || '/dashboard';

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }

    message.clearMessage();

    if (location.state?.infoMessage) {
      message.setInfoMessage(location.state.infoMessage);
    } else if (location.state?.errorMessage) {
      message.setErrorMessage(location.state.errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePasswordProviderSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let formData = new FormData(e.currentTarget);
    let email = formData.get('email') as string;
    let password = formData.get('password') as string;

    try {
      await loginPasswordProvider(email, password);

      message.clearMessage();

      navigate(from, { replace: true });
    } catch (err: any) {
      await errorService.handleComponentError(
        err,
        err.message || 'Error logging in with password provider',
        message
      );
    }
  };

  const handleSocialProviderClick = (e: any, onClick: any) => {
    e.preventDefault();

    onClick(e);
  };

  const handleFacebookLogin = async (response: any) => {
    try {
      setLoading(true);

      await loginSocialProvider(Provider.Facebook, response.accessToken);

      message.clearMessage();

      navigate(from, { replace: true });
    } catch (err: any) {
      await errorService.handleComponentError(
        err,
        err.message || 'Error logging in with facebook provider',
        message
      );
    }

    setLoading(false);
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      auth
        .loginSocialProvider(Provider.Google, tokenResponse.access_token)
        .then(() => message.clearMessage())
        .then(() => navigate(from, { replace: true }))
        .catch((err) =>
          errorService.handleComponentError(err, 'Error logging in with google provider', message)
        )
        .finally(() => setLoading(false));
    },
    onError: (errorResponse) => {
      errorService
        .handleComponentError(errorResponse, 'Error logging in with google provider', message)
        .then(() => setLoading(false));
    },
  });

  return (
    <>
      {loading ? (
        <div className="login-loading-container">
          <Loading />
        </div>
      ) : (
        <Container className="login-container">
          <Row>
            <Col sm="12">
              <div className="login-form">
                <Form onSubmit={handlePasswordProviderSubmit}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      maxLength={100}
                      value={location.state?.email}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Input type="password" name="password" id="password" maxLength={100} />
                  </FormGroup>

                  <FormGroup>
                    <Button>Submit</Button>
                  </FormGroup>
                </Form>
              </div>

              <div className="register-form">
                <Link to="/forgot-password" className="nav-link forgot-password-link">
                  Forgot password?
                </Link>
              </div>

              <div className="register-form">
                <Link to="/register" className="nav-link register-link">
                  Register an account
                </Link>
              </div>

              <div className="login-form">
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APPLICATION_ID as string}
                  isMobile={false}
                  callback={(response: any) => handleFacebookLogin(response)}
                  render={(renderProps: any) => (
                    <Button
                      color="primary"
                      onClick={(e: any) => handleSocialProviderClick(e, renderProps.onClick)}
                    >
                      Login with Facebook
                    </Button>
                  )}
                />
              </div>

              <div className="login-form">
                <Button
                  color="info"
                  onClick={() => {
                    setLoading(true);
                    handleGoogleLogin();
                  }}
                >
                  Login with Google
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default Login;
