import axios from 'axios';
import * as errorUtil from '../util/ErrorUtil';

const SCRANTHAPP_API_KEY = process.env.REACT_APP_SCRANTHAPP_API_KEY;

export const get = async (url: string, params: any, accessToken?: string, config?: any) => {
  try {
    return await axios.get(`${url}`, {
      ...config,
      ...(params && { params }),
      ...(accessToken && { headers: { ...buildCredentials(accessToken) } }),
    });
  } catch (err: any) {
    errorUtil.handleHttpError(err);
  }
};

export const post = async (url: string, data: any, accessToken?: string, config?: any) => {
  try {
    return await axios.post(`${url}`, data, {
      ...config,
      headers: { ...buildCredentials(accessToken) },
    });
  } catch (err: any) {
    errorUtil.handleHttpError(err);
  }
};

export const put = async (url: string, data: any, accessToken?: string, config?: any) => {
  try {
    return await axios.put(`${url}`, data, {
      ...config,
      ...(accessToken && { headers: { ...buildCredentials(accessToken) } }),
    });
  } catch (err: any) {
    errorUtil.handleHttpError(err);
  }
};

export const del = async (url: string, accessToken?: string, config?: any): Promise<void> => {
  try {
    await axios.delete(`${url}`, {
      ...config,
      ...(accessToken && { headers: { ...buildCredentials(accessToken) } }),
    });
  } catch (err: any) {
    errorUtil.handleHttpError(err);
  }
};

const buildCredentials = (accessToken?: string) => {
  return {
    'x-api-key': SCRANTHAPP_API_KEY,
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
  };
};
