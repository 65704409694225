import * as httpService from './HttpService';
import { IPageable } from '../model/Pageable';
import { PaginatedResponse } from '../model/PaginatedResponse';
import { ISubscriptionMembership } from '../model/SubscriptionMembership';

const SCRANTHAPP_API_URL = process.env.REACT_APP_SCRANTHAPP_API_URL;

export const getSubscriptionMemberships = async (
  accessToken: string,
  subscriptionId: string,
  pageable?: IPageable
): Promise<PaginatedResponse<ISubscriptionMembership[]>> => {
  const params = {
    ...(pageable && pageable.limit && { limit: pageable.limit }),
    ...(pageable && pageable.startValue && { startValue: pageable.startValue }),
    ...(pageable && pageable.sort && { sort: pageable.sort }),
  };

  const resp = await httpService.get(
    `${SCRANTHAPP_API_URL}/v1/subscriptions/${subscriptionId}/memberships?includeUserName=true`,
    params,
    accessToken
  );

  return resp ? resp.data : null;
};

export const getSubscriptionMembershipsBySubscriptionMembershipIds = async (
  accessToken: string,
  subscriptionId: string,
  subscriptionMembershipIds: string[],
  pageable?: IPageable
): Promise<PaginatedResponse<ISubscriptionMembership[]>> => {
  const params = {
    subscriptionMembershipIds,
    ...(pageable && pageable.limit && { limit: pageable.limit }),
    ...(pageable && pageable.startValue && { startValue: pageable.startValue }),
    ...(pageable && pageable.sort && { sort: pageable.sort }),
  };

  const resp = await httpService.post(
    `${SCRANTHAPP_API_URL}/v1/subscriptions/${subscriptionId}/memberships/search?includeUserName=true`,
    params,
    accessToken
  );

  return resp ? resp.data : null;
};
