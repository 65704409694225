import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import { useAuth } from '../hooks/useAuth';
import * as projectService from '../service/ProjectService';
import Loading from './Loading';
import './Projects.scss';
import * as subscriptionService from '../service/SubscriptionService';
import { useNavigate } from 'react-router-dom';
import { useMessage } from '../hooks/useMessage';
import * as errorService from '../service/ErrorService';
import { ValidationError } from '../model/ValidationError';
import { ISubscription } from '../model/Subscription';
import { IProject } from '../model/Project';
import * as dateUtil from '../util/DateUtil';

function Projects() {
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [subscription, setSubscription] = useState<ISubscription | null>(null);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [project, setProject] = useState<IProject | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string>('');

  const navigate = useNavigate();

  const toggleCreateModal = () => setCreateModal(!createModal);
  const toggleEditModal = () => setEditModal(!editModal);

  let { accessToken, refresh, user } = useAuth();
  let message = useMessage();

  useEffect(() => {
    const fetchData = async () => {
      const paginatedSubscriptions = await subscriptionService.getSubscriptions(accessToken);

      setSubscriptions(paginatedSubscriptions ? paginatedSubscriptions.data : []);

      const paginatedProjects = await projectService.getProjects(accessToken);

      setProjects(paginatedProjects.data);
    };

    fetchData()
      .catch((err: any) =>
        errorService.handleComponentError(
          err,
          'Error loading projects',
          message,
          refresh,
          user ? user.id : null
        )
      )
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateModal = async () => {
    message.clearMessage();

    setValidationError('');

    setCreateModal(!createModal);
  };

  const closeCreateModal = async () => {
    toggleCreateModal();
  };

  const openEditModal = async (projectId: string) => {
    message.clearMessage();

    setValidationError('');

    try {
      const project = await projectService.getProjectById(accessToken, projectId, true);

      setProject(project);

      const subscription = await subscriptionService.getSubscriptionById(
        accessToken,
        project.subscriptionId || ''
      );

      setSubscription(subscription);
    } catch (err: any) {
      await errorService.handleComponentError(
        err,
        'Error loading project',
        message,
        refresh,
        user ? user.id : null
      );
    }

    setEditModal(!editModal);
  };

  const closeEditModal = async () => {
    toggleEditModal();
  };

  const navigateToSubscriptions = (evt: any) => {
    evt.preventDefault();

    navigate('/dashboard/subscriptions');
  };

  const handleCreateSubmit = async (e: any) => {
    e.preventDefault();

    let formData = new FormData(e.currentTarget);
    let subscriptionId = formData.get('subscriptionId') as string;
    let name = formData.get('name') as string;
    let appType = formData.get('appType') as string;
    let permissions = formData.get('permissions') as string;
    let domainAllowList = formData.get('domainAllowList') as string;
    let serverAllowList = formData.get('serverAllowList') as string;

    if (!subscriptionId) {
      setValidationError('Subscription is required');
    } else if (!name.trim()) {
      setValidationError('Name is required');

      return;
    } else if (!appType.trim()) {
      setValidationError('App Type is required');

      return;
    }

    try {
      await projectService.createProject(
        accessToken,
        subscriptionId,
        name,
        appType,
        permissions ? permissions.split(',').map((item) => item.trim()) : [],
        domainAllowList ? domainAllowList.split(',').map((item) => item.trim()) : [],
        serverAllowList ? serverAllowList.split(',').map((item) => item.trim()) : []
      );

      const paginatedProjects = await projectService.getProjects(accessToken);

      setProjects(paginatedProjects.data);

      setValidationError('');

      toggleCreateModal();

      message.setInfoMessage('Project successfully created');
    } catch (err: any) {
      if (err instanceof ValidationError) {
        setValidationError(err.message);
      } else {
        await errorService.handleComponentError(
          err,
          'Error creating project',
          message,
          refresh,
          user ? user.id : null
        );

        setValidationError('');

        toggleCreateModal();
      }
    }
  };

  const handleEditSubmit = async (e: any, projectId: string, version: number) => {
    e.preventDefault();

    let formData = new FormData(e.currentTarget);
    let name = formData.get('name') as string;
    let appType = formData.get('appType') as string;
    let permissions = formData.get('permissions') as string;
    let domainAllowList = formData.get('domainAllowList') as string;
    let serverAllowList = formData.get('serverAllowList') as string;

    if (!name.trim()) {
      setValidationError('Name is required');

      return;
    } else if (!appType.trim()) {
      setValidationError('App Type is required');

      return;
    }

    try {
      await projectService.updateProject(
        accessToken,
        projectId,
        name,
        appType,
        permissions ? permissions.split(',').map((item) => item.trim()) : [],
        domainAllowList ? domainAllowList.split(',').map((item) => item.trim()) : [],
        serverAllowList ? serverAllowList.split(',').map((item) => item.trim()) : [],
        version
      );

      const paginatedProjects = await projectService.getProjects(accessToken);

      setProjects(paginatedProjects.data);

      setValidationError('');

      toggleEditModal();

      message.setInfoMessage('Project successfully updated');
    } catch (err: any) {
      if (err instanceof ValidationError) {
        setValidationError(err.message);
      } else {
        await errorService.handleComponentError(
          err,
          'Error updating project',
          message,
          refresh,
          user ? user.id : null
        );

        setValidationError('');

        toggleEditModal();
      }
    }
  };

  const handleDeleteSubmit = async (e: any, projectId: string) => {
    e.preventDefault();

    if (window.confirm('Are you sure?\n\n*Note: This will delete all related user associations')) {
      try {
        await projectService.deleteProject(accessToken, projectId);

        const paginatedProjects = await projectService.getProjects(accessToken);

        setProjects(paginatedProjects.data);

        setValidationError('');

        toggleEditModal();
      } catch (err: any) {
        if (err instanceof ValidationError) {
          setValidationError(err.message);
        } else {
          await errorService.handleComponentError(
            err,
            'Error updating subscription',
            message,
            refresh,
            user ? user.id : null
          );

          setValidationError('');

          toggleEditModal();
        }
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="projects-loading-container">
          <Loading />
        </div>
      ) : (
        <Container className="projects-container">
          <Row>
            <Col sm="12">
              {subscriptions.length > 0 && (
                <Button color="success" onClick={openCreateModal}>
                  Add Project
                </Button>
              )}

              {subscriptions.length === 0 && (
                <Button color="success" onClick={navigateToSubscriptions}>
                  Add Subscription
                </Button>
              )}

              <Modal isOpen={createModal} toggle={toggleCreateModal}>
                <ModalHeader toggle={toggleCreateModal}>
                  <div>Add Project</div>
                  {validationError && (
                    <div className="modal-validation-error">{validationError}</div>
                  )}
                </ModalHeader>

                <ModalBody>
                  <div>
                    <Form onSubmit={handleCreateSubmit}>
                      <FormGroup>
                        <Label for="subscriptionId">Subscription</Label>
                        <Input type="select" name="subscriptionId" id="subscriptionId">
                          <option value="">- select -</option>
                          {subscriptions.map((subscription) => (
                            <option key={subscription.id} value={subscription.id}>
                              {subscription.name}
                            </option>
                          ))}
                        </Input>
                        <FormText>(Required) Choose a subscription for this project</FormText>
                      </FormGroup>

                      <FormGroup>
                        <Label for="name">Name</Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          maxLength={100}
                          placeholder="ex. my new project"
                        />
                        <FormText>(Required) Add a name for your project</FormText>
                      </FormGroup>

                      <FormGroup>
                        <Label for="appType">App Type</Label>
                        <Input type="select" name="appType" id="appType">
                          <option value="">- select -</option>
                          <option value="web">Web</option>
                          <option value="spa">SPA</option>
                          <option value="hybrid">Hybrid</option>
                          <option value="native">Native</option>
                          <option value="api">API</option>
                        </Input>
                        <FormText>(Required) Choose an app type</FormText>
                      </FormGroup>

                      <FormGroup>
                        <Label for="permissions">Permissions</Label>
                        <Input
                          type="text"
                          name="permissions"
                          id="permissions"
                          maxLength={250}
                          placeholder="ex. add:project, view:users"
                        />
                        <FormText>
                          (Optional) Add your app's permissions, separated with comma
                        </FormText>
                      </FormGroup>

                      <FormGroup>
                        <Label for="domainAllowList">Domain Allow List</Label>
                        <Input
                          type="text"
                          name="domainAllowList"
                          id="domainAllowList"
                          maxLength={250}
                          placeholder="ex. myapp.com, localhost:3000"
                        />
                        <FormText>
                          (Optional) Add your app's accepted domains, separated with comma
                        </FormText>
                      </FormGroup>

                      <FormGroup>
                        <Label for="serverAllowList">Server Allow List</Label>
                        <Input
                          type="text"
                          name="serverAllowList"
                          id="serverAllowList"
                          maxLength={250}
                          placeholder="ex. api.myapp.com, 192.168.1.0"
                        />
                        <FormText>
                          (Optional) Add your app's accepted servers, separated with comma
                        </FormText>
                      </FormGroup>

                      <FormGroup className="modal-footer project-modal-footer">
                        <Button type="submit" color="primary">
                          Save
                        </Button>
                        <Button color="secondary" onClick={closeCreateModal}>
                          Cancel
                        </Button>
                      </FormGroup>
                    </Form>
                  </div>
                </ModalBody>
              </Modal>

              {project && subscription && (
                <Modal isOpen={editModal} toggle={toggleEditModal}>
                  <ModalHeader toggle={toggleEditModal}>
                    <div>Edit Project</div>
                    {validationError && (
                      <div className="modal-validation-error">{validationError}</div>
                    )}
                  </ModalHeader>

                  <ModalBody>
                    <div>
                      <Form
                        onSubmit={(evt) =>
                          handleEditSubmit(evt, project.id || '', project.version || 0)
                        }
                      >
                        <FormGroup>
                          <Label for="name">Name</Label>
                          <Input
                            type="text"
                            name="name"
                            id="name"
                            maxLength={100}
                            defaultValue={project.name}
                            placeholder="ex. my new project"
                          />
                          <FormText>(Required) Add a name for your project</FormText>
                        </FormGroup>

                        <FormGroup>
                          <Label for="appType">App Type</Label>
                          <Input
                            type="select"
                            name="appType"
                            id="appType"
                            defaultValue={project.appType}
                          >
                            <option value="">- select -</option>
                            <option value="web">Web</option>
                            <option value="spa">SPA</option>
                            <option value="hybrid">Hybrid</option>
                            <option value="native">Native</option>
                            <option value="api">API</option>
                          </Input>
                          <FormText>(Required) Choose an app type</FormText>
                        </FormGroup>

                        <FormGroup>
                          <Label for="subscriptionName">Subscription</Label>
                          <Input
                            type="text"
                            name="subscriptionName"
                            id="subscriptionName"
                            maxLength={100}
                            defaultValue={subscription.name}
                            disabled={true}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label for="permissions">Permissions</Label>
                          <Input
                            type="text"
                            name="permissions"
                            id="permissions"
                            maxLength={250}
                            defaultValue={
                              project.permissions ? `${project.permissions.join(', ')}` : ''
                            }
                            placeholder="ex. add:project, view:users"
                          />
                          <FormText>(Optional) Add your app's permissions</FormText>
                        </FormGroup>

                        <FormGroup>
                          <Label for="apiKey">API Key</Label>
                          <Input
                            type="text"
                            name="apiKey"
                            id="apiKey"
                            maxLength={100}
                            defaultValue={project.apiKey}
                            disabled={true}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label for="domainAllowList">Domain Allow List</Label>
                          <Input
                            type="text"
                            name="domainAllowList"
                            id="domainAllowList"
                            maxLength={250}
                            defaultValue={
                              project.domainAllowList ? `${project.domainAllowList.join(', ')}` : ''
                            }
                            placeholder="ex. myapp.com, localhost:3000"
                          />
                          <FormText>(Optional) Add your app's accepted domains</FormText>
                        </FormGroup>

                        <FormGroup>
                          <Label for="serverAllowList">Server Allow List</Label>
                          <Input
                            type="text"
                            name="serverAllowList"
                            id="serverAllowList"
                            maxLength={250}
                            defaultValue={
                              project.serverAllowList ? `${project.serverAllowList.join(', ')}` : ''
                            }
                            placeholder="ex. api.myapp.com, 192.168.1.0"
                          />
                          <FormText>(Optional) Add your app's accepted servers</FormText>
                        </FormGroup>

                        <FormGroup className="modal-footer project-modal-footer">
                          <Button type="submit" color="primary">
                            Save
                          </Button>
                          <Button color="secondary" onClick={closeEditModal}>
                            Cancel
                          </Button>
                          <Button
                            color="danger"
                            onClick={(evt) => handleDeleteSubmit(evt, project.id || '')}
                          >
                            Delete
                          </Button>
                        </FormGroup>
                      </Form>
                    </div>
                  </ModalBody>
                </Modal>
              )}
            </Col>
          </Row>

          {projects.length > 0 && (
            <Row className="projects-container">
              <Col sm="12">
                <Table size="sm" striped responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Subscription</th>
                      <th>App Type</th>
                      <th>Permissions</th>
                      <th>Created</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {projects.map((project) => (
                      <tr key={project.id}>
                        <td>{project.name}</td>
                        <td>
                          {
                            subscriptions.find(
                              (subscription) => subscription.id === project.subscriptionId
                            )?.name
                          }
                        </td>
                        <td className="uppercase-text">{project.appType}</td>
                        <td>{project.permissions ? `[${project.permissions.join(', ')}]` : ''}</td>
                        <td>{dateUtil.format(project.created, 'MM/DD/YYYY HH:mm:ss')}</td>
                        <td>
                          <Button
                            color="secondary"
                            onClick={() => openEditModal(project['id'] || '')}
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}

          {projects.length === 0 && subscriptions.length > 0 && (
            <Row className="first-project-message">
              <Col sm="12">It's time to create your first project!</Col>
            </Row>
          )}

          {projects.length === 0 && subscriptions.length === 0 && (
            <Row className="first-project-message">
              <Col sm="12">
                It's time to create your first project, but first, create a subscription!
              </Col>
            </Row>
          )}
        </Container>
      )}
    </>
  );
}

export default Projects;
