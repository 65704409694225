import * as httpService from './HttpService';
import { IPageable } from '../model/Pageable';
import { IProject } from '../model/Project';
import { PaginatedResponse } from '../model/PaginatedResponse';

const SCRANTHAPP_API_URL = process.env.REACT_APP_SCRANTHAPP_API_URL;

export const getProjects = async (
  accessToken: string,
  pageable?: IPageable
): Promise<PaginatedResponse<IProject[]>> => {
  const params = {
    ...(pageable && pageable.limit && { limit: pageable.limit }),
    ...(pageable && pageable.startValue && { startValue: pageable.startValue }),
    ...(pageable && pageable.sort && { sort: pageable.sort }),
  };

  const resp = await httpService.get(`${SCRANTHAPP_API_URL}/v1/projects`, params, accessToken);

  return resp ? resp.data : null;
};

export const getProjectsByProjectIds = async (
  accessToken: string,
  projectIds: string[],
  pageable?: IPageable
): Promise<PaginatedResponse<IProject[]>> => {
  const params = {
    projectIds,
    ...(pageable && pageable.limit && { limit: pageable.limit }),
    ...(pageable && pageable.startValue && { startValue: pageable.startValue }),
    ...(pageable && pageable.sort && { sort: pageable.sort }),
  };

  const resp = await httpService.post(
    `${SCRANTHAPP_API_URL}/v1/projects/search`,
    params,
    accessToken
  );

  return resp ? resp.data : null;
};

export const getProjectById = async (
  accessToken: string,
  projectId: string,
  includeProjectAuth: boolean
): Promise<IProject> => {
  const resp = await httpService.get(
    `${SCRANTHAPP_API_URL}/v1/projects/${projectId}?includeProjectAuth=${includeProjectAuth}`,
    null,
    accessToken
  );

  return resp ? resp.data : null;
};

export const createProject = async (
  accessToken: string,
  subscriptionId: string,
  name: string,
  appType: string,
  permissions: string[],
  domainAllowList: string[],
  serverAllowList: string[]
): Promise<IProject> => {
  const resp = await httpService.post(
    `${SCRANTHAPP_API_URL}/v1/projects`,
    {
      subscriptionId,
      name,
      appType,
      permissions,
      domainAllowList,
      serverAllowList,
    },
    accessToken
  );

  return resp ? resp.data : null;
};

export const updateProject = async (
  accessToken: string,
  projectId: string,
  name: string,
  appType: string,
  permissions: string[],
  domainAllowList: string[],
  serverAllowList: string[],
  version: number
): Promise<IProject> => {
  const resp = await httpService.put(
    `${SCRANTHAPP_API_URL}/v1/projects/${projectId}`,
    {
      name,
      appType,
      permissions,
      domainAllowList,
      serverAllowList,
      version,
    },
    accessToken
  );

  return resp ? resp.data : null;
};

export const deleteProject = async (accessToken: string, projectId: string): Promise<void> => {
  await httpService.del(`${SCRANTHAPP_API_URL}/v1/projects/${projectId}`, accessToken);
};
