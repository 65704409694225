import LoginsChart from './LoginsChart';
import { Col, Container, Row } from 'reactstrap';
import { useAuth } from '../hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { IUserSettings } from '../model/UserSettings';
import * as userSettingsService from '../service/UserSettingsService';
import * as errorService from '../service/ErrorService';
import { useMessage } from '../hooks/useMessage';
import Loading from './Loading';
import './Dashboard.scss';

function Dashboard() {
  const [userSettings, setUserSettings] = useState<IUserSettings | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  let { accessToken, refresh, user } = useAuth();
  let message = useMessage();

  useEffect(() => {
    const fetchData = async () => {
      const paginatedUserSettings = await userSettingsService.getUserSettings(
        accessToken,
        user && user.id ? user.id : ''
      );

      setUserSettings(paginatedUserSettings ? paginatedUserSettings.data[0] : null);
    };

    fetchData()
      .catch((err: any) =>
        errorService.handleComponentError(
          err,
          'Error loading user settings',
          message,
          refresh,
          user ? user.id : null
        )
      )
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <div className="dashboard-loading-container">
          <Loading />
        </div>
      ) : (
        <Container className="dashboard-container">
          <Row>
            <Col sm="12">
              {userSettings && userSettings.displayChartDashboard && <LoginsChart />}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default Dashboard;
