import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import './NavigationBar.scss';
import { useAuth } from '../hooks/useAuth';

function NavigationBar() {
  let { user } = useAuth();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Container className="navigation-bar-container">
      <Row>
        <Col>
          <Navbar expand="md" color="#209CEE">
            <NavbarBrand href="/">
              <span className="logo-text">{'<ScranthApp />'}</span>
            </NavbarBrand>
            {user && <NavbarToggler onClick={toggle} />}
            {user && (
              <Collapse isOpen={isOpen} navbar>
                <Nav className="ms-auto" navbar>
                  <NavItem>
                    <NavLink href="/dashboard/subscriptions">Subscriptions</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/dashboard/projects">Projects</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/dashboard/users">Users</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/dashboard/files">Files</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/dashboard/charts">Charts</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/dashboard/settings">Settings</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/logout">Logout</NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            )}
          </Navbar>
        </Col>
      </Row>
    </Container>
  );
}

export default NavigationBar;
