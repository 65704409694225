import { stringify as circularStringify } from 'flatted';

export const isBlank = (source: any): boolean => {
  return isString(source) ? /^\s*$/.test(source) : true;
};

export const isString = (source: any): boolean => {
  return source ? typeof source === 'string' : false;
};

export const stringify = (source: any): string => {
  return circularStringify(source);
};

export const stringifyError = (err: any): string => {
  const errObject = {} as any;

  Object.getOwnPropertyNames(err).forEach((key) => {
    errObject[key] = err[key];
  });

  return circularStringify(errObject);
};

export const base64Encode = (data: any): string => {
  return Buffer.from(data).toString('base64');
};

export const base64Decode = (data: any): string => {
  return Buffer.from(data, 'base64').toString('ascii');
};
