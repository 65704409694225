function Fallback({ error, resetErrorBoundary }: { error: any; resetErrorBoundary: any }) {
  return (
    <div role="alert">
      <p>An unexpected error occurred.</p>

      <button onClick={resetErrorBoundary}>Please try again</button>
    </div>
  );
}

export default Fallback;
