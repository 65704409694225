import { post } from './HttpService';

const SCRANTHAPP_API_URL = process.env.REACT_APP_SCRANTHAPP_API_URL;

export const createAccessToken = async (
  provider: string,
  email: any,
  password: any,
  providerAccessToken: any
) => {
  const body = {
    provider,
    ...(email && { email }),
    ...(password && { password }),
    ...(providerAccessToken && { providerAccessToken }),
  };

  const resp = await post(`${SCRANTHAPP_API_URL}/v1/auth/token`, body);

  return resp && resp.data ? resp.data.accessToken : null;
};

export const refreshAccessToken = async (accessToken: string) => {
  const resp = await post(`${SCRANTHAPP_API_URL}/v1/auth/refresh-token`, { accessToken });

  return resp && resp.data ? resp.data.accessToken : null;
};
