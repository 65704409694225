import React, { useEffect, useRef, useState } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import './Charts.scss';
import { useAuth } from '../hooks/useAuth';
import * as errorService from '../service/ErrorService';
import { useMessage } from '../hooks/useMessage';
import { Button, Col, Container, Row } from 'reactstrap';

function Charts() {
  const sdk = new ChartsEmbedSDK({ baseUrl: process.env.REACT_APP_CHARTS_BASE_URL });
  const dashboardDiv = useRef(null);
  const [dashboard] = useState(
    sdk.createDashboard({
      height: '100vh',
      showTitleAndDesc: true,
      showAttribution: false,
      getUserToken: () => accessToken,
      dashboardId: process.env.REACT_APP_CHARTS_DASHBOARD_ID,
      theme: 'light',
    })
  );

  let { accessToken, refresh, user } = useAuth();
  let message = useMessage();

  useEffect(() => {
    const fetchData = async () => {
      await dashboard.render(dashboardDiv.current!);
    };

    fetchData().catch((err: any) =>
      errorService.handleComponentError(
        err,
        'Error loading charts dashboard',
        message,
        refresh,
        user ? user.id : null
      )
    );
  }, [dashboard, message, refresh, user]);

  const refreshDashboard = async () => {
    await dashboard.refresh();
  };

  return (
    <>
      <Container className="charts-container">
        <Row>
          <Col sm="12">
            <Button type="submit" color="primary" onClick={refreshDashboard}>
              Refresh
            </Button>
          </Col>
        </Row>

        <Row className="charts-dashboard">
          <Col sm="12">
            <div className="chart" ref={dashboardDiv} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Charts;
