import dayjs from 'dayjs';

export const isValid = (source: any): boolean => {
  return dayjs(source).isValid();
};

export const toDate = (source: string): Date | null => {
  return isValid(source) ? dayjs(source).toDate() : null;
};

export const toISOString = (source?: any): string | null => {
  return isValid(source) ? dayjs(source).toISOString() : null;
};

export const toMilliseconds = (source?: any): number => {
  return isValid(source) ? dayjs(source).valueOf() : 0;
};

export const now = (): Date => {
  return dayjs().toDate();
};

export const isAfter = (source: any, date: Date): boolean => {
  return isValid(source) ? dayjs(source).isAfter(date) : false;
};

export const add = (source: any, val: number, unit: any): Date | null => {
  return isValid(source) ? dayjs(source).add(val, unit).toDate() : null;
};

export const format = (source: any, template: string): string => {
  return isValid(source) ? dayjs(source).format(template) : '';
};
