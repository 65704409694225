import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { useAuth } from '../hooks/useAuth';
import './Settings.scss';
import * as userService from '../service/UserService';
import React, { useEffect, useState } from 'react';
import { IUserSettings } from '../model/UserSettings';
import * as userSettingsService from '../service/UserSettingsService';
import * as errorService from '../service/ErrorService';
import { useMessage } from '../hooks/useMessage';
import Loading from './Loading';
import { ValidationError } from '../model/ValidationError';
import * as numberUtil from '../util/NumberUtil';

function Settings() {
  const [userSettings, setUserSettings] = useState<IUserSettings | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [displayChartDashboard, setDisplayChartDashboard] = useState<boolean>(true);
  const [maxProjectUsers, setMaxProjectUsers] = useState<number>(0);
  const [favColor, setFavColor] = useState<string>('');

  let { accessToken, refresh, user } = useAuth();
  let message = useMessage();

  useEffect(() => {
    const fetchData = async () => {
      const paginatedUserSettings = await userSettingsService.getUserSettings(
        accessToken,
        user && user.id ? user.id : ''
      );

      const userSettings = paginatedUserSettings ? paginatedUserSettings.data : null;

      if (userSettings) {
        setUserSettings(userSettings[0]);
        setDisplayChartDashboard(userSettings[0].displayChartDashboard);
        setMaxProjectUsers(userSettings[0].maxProjectUsers || 0);
        setFavColor(userSettings[0].favColor || '');
      }
    };

    fetchData()
      .catch((err: any) =>
        errorService.handleComponentError(
          err,
          'Error loading settings',
          message,
          refresh,
          user ? user.id : null
        )
      )
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDisplayChartDashboard = async (e: any, userSettingsId: string, version: number) => {
    e.preventDefault();

    const val = e.currentTarget.checked;

    setDisplayChartDashboard(val);

    try {
      const userSettings = await userSettingsService.updateUserSettings(
        accessToken,
        userSettingsId,
        user && user.id ? user.id : '',
        val,
        maxProjectUsers,
        favColor,
        version
      );

      setUserSettings(userSettings);

      message.clearMessage();

      message.setInfoMessage('User settings successfully updated');
    } catch (err: any) {
      if (err instanceof ValidationError) {
        message.setErrorMessage(err.message);
      } else {
        await errorService.handleComponentError(
          err,
          'Error updating user settings',
          message,
          refresh,
          user ? user.id : null
        );

        message.clearMessage();
      }
    }
  };

  const updateMaxProjectUsers = async (e: any, userSettingsId: string, version: number) => {
    e.preventDefault();

    const val = e.currentTarget.value;

    if (!val || (val && !numberUtil.isNumeric(Number(val)))) {
      message.setErrorMessage('Max project users is numeric');

      return;
    }

    setMaxProjectUsers(Number(val));

    if (Number(val) !== maxProjectUsers) {
      try {
        const userSettings = await userSettingsService.updateUserSettings(
          accessToken,
          userSettingsId,
          user && user.id ? user.id : '',
          displayChartDashboard,
          Number(val),
          favColor,
          version
        );

        setUserSettings(userSettings);

        message.clearMessage();

        message.setInfoMessage('User settings successfully updated');
      } catch (err: any) {
        if (err instanceof ValidationError) {
          message.setErrorMessage(err.message);
        } else {
          await errorService.handleComponentError(
            err,
            'Error updating user settings',
            message,
            refresh,
            user ? user.id : null
          );

          message.clearMessage();
        }
      }
    }
  };

  const updateFavColorDashboard = async (e: any, userSettingsId: string, version: number) => {
    e.preventDefault();

    const val = e.currentTarget.value;

    setFavColor(val);

    try {
      const userSettings = await userSettingsService.updateUserSettings(
        accessToken,
        userSettingsId,
        user && user.id ? user.id : '',
        displayChartDashboard,
        maxProjectUsers,
        val,
        version
      );

      setUserSettings(userSettings);

      message.clearMessage();

      message.setInfoMessage('User settings successfully updated');
    } catch (err: any) {
      if (err instanceof ValidationError) {
        message.setErrorMessage(err.message);
      } else {
        await errorService.handleComponentError(
          err,
          'Error updating user settings',
          message,
          refresh,
          user ? user.id : null
        );

        message.clearMessage();
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="settings-loading-container">
          <Loading />
        </div>
      ) : (
        <Container className="settings-container">
          <Row>
            <Col sm="12">
              <div className="user-info">
                <div className="user-picture-container">
                  <img src={userService.getUserPicture(user)} alt="user" className="user-picture" />
                </div>
                <div className="user-name">{userService.getUserName(user)}</div>
              </div>
            </Col>
          </Row>

          {userSettings && (
            <Row>
              <Col sm="12">
                <Row>
                  <Col>
                    <div className="user-settings">
                      <Form>
                        <FormGroup row>
                          <Label for="checkbox2" sm={2}>
                            Display chart on dashboard
                          </Label>
                          <Col
                            sm={{
                              size: 10,
                            }}
                          >
                            <FormGroup switch>
                              <Input
                                type="switch"
                                checked={displayChartDashboard}
                                onClick={(evt) =>
                                  updateDisplayChartDashboard(
                                    evt,
                                    userSettings.id || '',
                                    userSettings.version || 0
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="maxProjectUsers" sm={2}>
                            Max project users
                          </Label>
                          <Col sm={10}>
                            <Input
                              id="maxProjectUsers"
                              name="maxProjectUsers"
                              placeholder=""
                              type="text"
                              maxLength={5}
                              defaultValue={maxProjectUsers}
                              onBlur={(evt) =>
                                updateMaxProjectUsers(
                                  evt,
                                  userSettings.id || '',
                                  userSettings.version || 0
                                )
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="favColor" sm={2}>
                            Favorite color
                          </Label>
                          <Col sm={10}>
                            <Input
                              id="favColor"
                              name="favColor"
                              type="select"
                              defaultValue={favColor}
                              onChange={(evt) =>
                                updateFavColorDashboard(
                                  evt,
                                  userSettings.id || '',
                                  userSettings.version || 0
                                )
                              }
                            >
                              <option>- select -</option>
                              <option>red</option>
                              <option>blue</option>
                              <option>green</option>
                            </Input>
                          </Col>
                        </FormGroup>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Container>
      )}
    </>
  );
}

export default Settings;
