import './Register.scss';
import React, { useEffect } from 'react';
import * as userService from '../service/UserService';
import * as errorService from '../service/ErrorService';
import { useNavigate, useSearchParams } from 'react-router-dom';

function Verify() {
  const [queryParameters] = useSearchParams();

  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const code = queryParameters.get('code');

      let email;

      try {
        email = await userService.verify(code || '');

        navigate('/login', { state: { infoMessage: 'Email verified. Login to continue', email } });
      } catch (err) {
        navigate('/', { state: { errorMessage: 'Invalid verification code' } });
      }
    };

    fetchData().catch((err: any) => errorService.handleComponentError(err, 'Error verifying user'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}

export default Verify;
