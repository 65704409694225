import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { useAuth } from '../hooks/useAuth';
import * as projectService from '../service/ProjectService';
import * as fileService from '../service/FileService';
import Loading from './Loading';
import './Files.scss';
import * as subscriptionService from '../service/SubscriptionService';
import { useMessage } from '../hooks/useMessage';
import * as errorService from '../service/ErrorService';
import { ISubscription } from '../model/Subscription';
import { IProject } from '../model/Project';
import { IFile } from '../model/File';
import * as dateUtil from '../util/DateUtil';
import FileUpload from './FileUpload';

function Files() {
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  let { accessToken, refresh, user } = useAuth();
  let message = useMessage();

  useEffect(() => {
    const fetchData = async () => {
      const paginatedSubscriptions = await subscriptionService.getSubscriptions(accessToken);

      setSubscriptions(paginatedSubscriptions ? paginatedSubscriptions.data : []);

      const paginatedProjects = await projectService.getProjects(accessToken);

      setProjects(paginatedProjects.data);

      const paginatedFiles = await fileService.getFiles(accessToken);

      setFiles(paginatedFiles.data);
    };

    fetchData()
      .catch((err: any) =>
        errorService.handleComponentError(
          err,
          'Error loading files',
          message,
          refresh,
          user ? user.id : null
        )
      )
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initiateFileDownload = async (fileId: string, fileName: string) => {
    const resp = await fileService.downloadFile(accessToken, fileId);

    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);

    document.body.appendChild(link);

    link.click();
  };

  const handleDeleteSubmit = async (e: any, fileId: string) => {
    e.preventDefault();

    if (window.confirm('Are you sure?')) {
      try {
        await fileService.deleteFile(accessToken, fileId);

        const paginatedFiles = await fileService.getFiles(accessToken);

        setFiles(paginatedFiles.data);
      } catch (err: any) {
        await errorService.handleComponentError(
          err,
          'Error deleting file',
          message,
          refresh,
          user ? user.id : null
        );
      }
    }
  };

  const onUpload = async () => {
    try {
      const paginatedFiles = await fileService.getFiles(accessToken);

      setFiles(paginatedFiles.data);
    } catch (err: any) {
      await errorService.handleComponentError(
        err,
        'Error loading files',
        message,
        refresh,
        user ? user.id : null
      );
    }
  };

  return (
    <>
      {loading ? (
        <div className="files-loading-container">
          <Loading />
        </div>
      ) : (
        <Container className="files-container">
          {subscriptions.length > 0 && projects.length > 0 && (
            <Row className="file-upload-container">
              <Col sm="12">
                <FileUpload
                  accessToken={accessToken}
                  subscriptionId={subscriptions[0].id || ''}
                  projectId={projects[0].id || ''}
                  onUpload={onUpload}
                />
              </Col>
            </Row>
          )}

          {subscriptions.length > 0 && projects.length > 0 && files.length > 0 && (
            <Row>
              <Col sm="12">
                <Table size="sm" striped responsive>
                  <thead>
                    <tr>
                      <th>Subscription</th>
                      <th>Project</th>
                      <th>File Name</th>
                      <th>File Size</th>
                      <th>Mime Type</th>
                      <th>Created</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((file) => (
                      <tr key={file.id}>
                        <td>
                          {
                            subscriptions.find(
                              (subscription) => subscription.id === file.subscriptionId
                            )?.name
                          }
                        </td>
                        <td>{projects.find((project) => project.id === file.projectId)?.name}</td>
                        <td>{file.fileName}</td>
                        <td>{file.fileSize} B</td>
                        <td>{file.mimeType}</td>
                        <td>{dateUtil.format(file.created, 'MM/DD/YYYY HH:mm:ss')}</td>
                        <td>
                          <Button
                            color="success"
                            onClick={() =>
                              initiateFileDownload(file['id'] || '', file['fileName'] || '')
                            }
                          >
                            Download
                          </Button>

                          <Button
                            className="file-delete-button"
                            color="danger"
                            onClick={(evt) => handleDeleteSubmit(evt, file['id'] || '')}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
        </Container>
      )}
    </>
  );
}

export default Files;
