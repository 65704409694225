import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import './Home.scss';
import { useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';

function Home() {
  let auth = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (auth.user) {
      navigate('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetStarted = async (evt: any) => {
    evt.preventDefault();

    navigate('/login');
  };

  return (
    <Container className="home-container">
      <Row>
        <Col sm="12">
          <div>
            Welcome to ScranthApp!
            <br />
            <br />
            <Button color="primary" onClick={handleGetStarted}>
              Get Started
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
