import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import './ForgotPassword.scss';
import * as userService from '../service/UserService';
import React from 'react';
import * as errorService from '../service/ErrorService';
import { useMessage } from '../hooks/useMessage';

function ForgotPassword() {
  let message = useMessage();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let formData = new FormData(e.currentTarget);
    let email = formData.get('email') as string;

    try {
      if (email) {
        await userService.forgotPassword(email);

        message.setInfoMessage('A reset password link has been emailed');
      } else {
        message.setErrorMessage('Email is required');
      }
    } catch (err: any) {
      await errorService.handleComponentError(
        err,
        err.message || 'Error resetting user password',
        message
      );
    }
  };

  return (
    <Container>
      <Row>
        <Col sm="12">
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input type="email" name="email" id="email" />
            </FormGroup>

            <FormGroup>
              <Button>Submit</Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
