import React, { useEffect, useState } from 'react';
import {
  Col,
  Container,
  Pagination as ReactPagination,
  PaginationItem,
  PaginationLink,
  Row,
} from 'reactstrap';
import './Pagination.scss';
import Loading from './Loading';
import { Sort } from '../model/Sort';
import { IPageable } from '../model/Pageable';

interface Props {
  items: any[];
  setItems: any;
  fetchItemsFunction: (pageable: IPageable) => Promise<any>;
  onError: (err: any) => Promise<any>;
  limit: number;
}

function Pagination(props: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [prevStartValue, setPrevStartValue] = useState<Date | null>(null);
  const [nextStartValue, setNextStartValue] = useState<Date | null>(null);

  useEffect(() => {
    if (props.items.length < props.limit) {
      setNextStartValue(null);
    } else {
      const lastItem = props.items.at(-1);

      setNextStartValue(lastItem && lastItem.created ? lastItem.created : null);
    }

    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaginationClick = async (e: any, sort: Sort) => {
    e.preventDefault();

    setLoading(true);

    let items;

    try {
      items = await props.fetchItemsFunction({
        limit: props.limit,
        startValue: sort === Sort.Desc ? nextStartValue : prevStartValue,
        sort,
      });

      const firstItem = items.at(0);
      const lastItem = items.at(-1);

      if (!items || items.length === 0) {
        if (sort === Sort.Asc) {
          setPrevStartValue(null);
        } else {
          setNextStartValue(null);
        }
      } else {
        setPrevStartValue(firstItem && firstItem.created ? firstItem.created : null);

        setNextStartValue(lastItem && lastItem.created ? lastItem.created : null);

        props.setItems(items);
      }
    } catch (err: any) {
      await props.onError(err);
    }

    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <div className="pagination-loading-container">
          <Loading />
        </div>
      ) : (
        <Container className="pagination-container">
          {props.items.length > 0 && (
            <Row>
              <Col sm="12" className="pagination-column">
                <ReactPagination>
                  <PaginationItem disabled={!prevStartValue}>
                    <PaginationLink
                      onClick={(evt) => handlePaginationClick(evt, Sort.Asc)}
                      previous
                    />
                  </PaginationItem>

                  <PaginationItem disabled={!nextStartValue}>
                    <PaginationLink onClick={(evt) => handlePaginationClick(evt, Sort.Desc)} next />
                  </PaginationItem>
                </ReactPagination>
              </Col>
            </Row>
          )}
        </Container>
      )}
    </>
  );
}

export default Pagination;
