import React from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import './Loading.scss';

function Loading() {
  return (
    <Container className="loading-container">
      <Row>
        <Col>
          <Spinner color="light" type="grow">
            Loading...
          </Spinner>
        </Col>
      </Row>
    </Container>
  );
}

export default Loading;
