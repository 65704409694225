import * as httpService from './HttpService';
import { IPageable } from '../model/Pageable';
import { PaginatedResponse } from '../model/PaginatedResponse';
import { ISubscription } from '../model/Subscription';

const SCRANTHAPP_API_URL = process.env.REACT_APP_SCRANTHAPP_API_URL;

export const getSubscriptions = async (
  accessToken: string,
  pageable?: IPageable
): Promise<PaginatedResponse<ISubscription[]>> => {
  const params = {
    ...(pageable && pageable.limit && { limit: pageable.limit }),
    ...(pageable && pageable.startValue && { startValue: pageable.startValue }),
    ...(pageable && pageable.sort && { sort: pageable.sort }),
  };

  const resp = await httpService.get(`${SCRANTHAPP_API_URL}/v1/subscriptions`, params, accessToken);

  return resp ? resp.data : null;
};

export const getSubscriptionsBySubscriptionIds = async (
  accessToken: string,
  subscriptionIds: string[],
  pageable?: IPageable
): Promise<PaginatedResponse<ISubscription[]>> => {
  const params = {
    subscriptionIds,
    ...(pageable && pageable.limit && { limit: pageable.limit }),
    ...(pageable && pageable.startValue && { startValue: pageable.startValue }),
    ...(pageable && pageable.sort && { sort: pageable.sort }),
  };

  const resp = await httpService.post(
    `${SCRANTHAPP_API_URL}/v1/subscriptions/search`,
    params,
    accessToken
  );

  return resp ? resp.data : null;
};

export const getSubscriptionById = async (
  accessToken: string,
  subscriptionId: string
): Promise<ISubscription> => {
  const resp = await httpService.get(
    `${SCRANTHAPP_API_URL}/v1/subscriptions/${subscriptionId}`,
    null,
    accessToken
  );

  return resp ? resp.data : null;
};

export const createSubscription = async (
  accessToken: string,
  name: string,
  orgName: string,
  planType: string
): Promise<ISubscription> => {
  const resp = await httpService.post(
    `${SCRANTHAPP_API_URL}/v1/subscriptions`,
    {
      name,
      orgName,
      planType,
    },
    accessToken
  );

  return resp ? resp.data : null;
};

export const updateSubscription = async (
  accessToken: string,
  subscriptionId: string,
  name: string,
  orgName: string,
  planType: string,
  version: number
): Promise<ISubscription> => {
  const resp = await httpService.put(
    `${SCRANTHAPP_API_URL}/v1/subscriptions/${subscriptionId}`,
    {
      name,
      orgName,
      planType,
      version,
    },
    accessToken
  );

  return resp ? resp.data : null;
};

export const deleteSubscription = async (
  accessToken: string,
  subscriptionId: string
): Promise<void> => {
  await httpService.del(`${SCRANTHAPP_API_URL}/v1/subscriptions/${subscriptionId}`, accessToken);
};
