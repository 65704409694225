import { Container, Row, Col, UncontrolledAlert } from 'reactstrap';
import './MessageBar.scss';
import { useMessage } from '../hooks/useMessage';

function MessageBar() {
  let message = useMessage();

  const onDismiss = () => {
    message.setInfoMessage('');
    message.setErrorMessage('');
  };

  const color = message.infoMessage ? 'success' : 'danger';

  return (
    <>
      {message && (
        <Container>
          <Row>
            <Col sm="12">
              {(message.infoMessage || message.errorMessage) && (
                <UncontrolledAlert
                  className="message-alert"
                  color={color}
                  isOpen={!!message.infoMessage || !!message.errorMessage}
                  toggle={onDismiss}
                >
                  <div className="message">{message.infoMessage || message.errorMessage}</div>
                </UncontrolledAlert>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default MessageBar;
