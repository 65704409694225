import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import './Register.scss';
import * as userService from '../service/UserService';
import React from 'react';
import * as errorService from '../service/ErrorService';
import { useMessage } from '../hooks/useMessage';

function Register() {
  let message = useMessage();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let formData = new FormData(e.currentTarget);
    let name = formData.get('name') as string;
    let email = formData.get('email') as string;
    let password = formData.get('password') as string;

    try {
      if (name && email && password) {
        await userService.register(name, email, password);

        message.setInfoMessage('Almost there. Check your email for a verification link');
      } else {
        message.setErrorMessage('Name and Email and Password required');
      }
    } catch (err: any) {
      await errorService.handleComponentError(
        err,
        err.message || 'Error registering user',
        message
      );
    }
  };

  return (
    <Container className="register-container">
      <Row>
        <Col sm="12">
          <div className="login-form">
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input type="text" name="name" id="name" maxLength={100} />
              </FormGroup>

              <FormGroup>
                <Label for="email">Email</Label>
                <Input type="email" name="email" id="email" maxLength={100} />
              </FormGroup>

              <FormGroup>
                <Label for="password">Password</Label>
                <Input type="password" name="password" id="password" maxLength={100} />
              </FormGroup>

              <FormGroup>
                <Button>Submit</Button>
              </FormGroup>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Register;
